<template>
    <v-app fluid style="background-color: #485461;
        background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12">
                    <v-toolbar dark color="#84109a">
                        <v-spacer />
                        <b>ACESSO À LOJA FÁCIL</b>
                        <v-spacer />
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field label="E-mail" ref="login" prepend-icon="person" type="text" v-model="email"
                                autocomplete="username" @keypress.enter="$refs.password.focus()" />

                            <v-text-field label="Senha" ref="password" v-model="password" prepend-icon="lock"
                                @keypress.enter="login" autocomplete="current-password"
                                :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn block @click="login" color="#84109a" :loading="loading" dark>Entrar</v-btn>
                    </v-card-actions>
                    <v-row class="mt-4">
                        <v-col>
                            <v-btn @click="createNewAccount" text small outlined color="primary">Criar nova conta</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn @click="passwordReset" text small>Esqueci minha senha</v-btn>
                        </v-col>
                    </v-row>

                    <br>
                    <v-toolbar dark color="#84109a" style="height: 4px;">
                        <v-spacer />
                        <span style="color: #f3ef0d; font-size: 13px; font-family: sans-serif;"><b>Se você deseja ser ou se
                                já é uma REVENDA
                                <a href="https://revenda.lojafacil.app" target="_blank" style="color: #f3880d;">
                                    clique aqui</a></b>
                        </span>
                        <v-spacer />
                    </v-toolbar>

                </v-card>
            </v-col>
        </v-row>
        <NewAccountDialog :visible="newAccountDialogVisible" @newAccount="onNewAccount"
            @close="newAccountDialogVisible = false" />
    </v-app>
</template>

<script>
import NewAccountDialog from '@/views/account/NewAccountDialog';

export default {
    name: 'LoginView',

    components: {
        NewAccountDialog,
    },

    data: () => ({
        email: '',
        password: '',
        loading: false,
        showPassword: false,
        newAccountDialogVisible: false,
    }),

    methods: {
        login() {
            if (!this.email) {
                this.$toast.error('Informe o e-mail', 'warning');
                return;
            }
            if (!this.password) {
                this.$toast.error('Informe a senha', 'warning');
                return;
            }

            this.loading = true;
            this.$http.post('login', {
                email: this.email,
                password: this.password
            }).then(async (resp) => {
                const user = resp.data;

                if (user.token) {
                    this.makeLogin(user);
                    return;
                }

                const msg = resp.data?.msg || 'Usuário ou senha inválido';
                this.$toast.error(msg);
            })
                .catch(() => (this.$toast.error('Não foi possivel realizar login')))
                .finally(() => (this.loading = false))
        },

        makeLogin(user) {
            sessionStorage.setItem('token', user.token);

            this.$http.defaults.headers.common = { 'Authorization': `Bearer ${user.token}` };

            this.$store.commit('setUser', user);
            this.$store.commit('setCart', []);

            const route = user.tipo == 'admin' ? '/dashboard' : '/';
            this.$router.push(route);
        },

        passwordReset() {
            this.$router.push('/forgot-password');
        },

        createNewAccount() {
            this.newAccountDialogVisible = true;
        },

        onNewAccount(e) {
            this.email = e;
            this.newAccountDialogVisible = false;
        },
    }
}
</script>

<style scoped>
.container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.container .row {
    margin: auto;
    justify-content: center;
}
</style>
